import React from 'react'
import WhereImage from "./whereImage"

const WhereHero = () => (
  <div className="container max">
    <div className="row py-5 align-items-center"
      style={{
        minHeight: 'calc(-228px + 100vh)'
      }}>
      <div className="col-md-5">
        <h2>WHERE TO BUY MASTERCARE</h2>
        <p>MasterCare is available for purchase through select HVAC/R Wholesalers.  Click below to find a location near you.</p>
        <a href="https://www.johnstonesupply.com/storefront/static/findAStore.ep" className="btn--dark mt-2">FIND A RETAILER NEAR YOU</a>
      </div>
      <div className="col-md-6 offset-md-1">
        <WhereImage />
      </div>
    </div>
  </div>

)
export default WhereHero