import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WhereHero from '../components/where-components/whereHero'

const WherePage = () => (
  <Layout>
    <SEO title="Where To Buy" />
    <WhereHero />
  </Layout>
)

export default WherePage
